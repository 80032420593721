<template>
	<div class="auth-wrapper auth-v1 px-2">
		<div class="auth-inner py-2">
			<!-- Reset Password v1 -->
			<b-card class="mb-0">
				<!-- logo -->
				<b-link class="brand-logo">
					<vuexy-logo />
				</b-link>

				<div id="loading-container" v-if="isLoadingCode">
					<b-spinner
						style="width: 3rem; height: 3rem"
						variant="warning"
					/>
				</div>

				<div v-else-if="showUpdateSuccess">
					Your password has been reset. Please go to login.
				</div>

				<template v-else>
					<b-card-title class="mb-1">
						Reset Password 🔒
					</b-card-title>
					<b-card-text class="mb-2">
						Your new password must be different from previously used passwords
					</b-card-text>

					<!-- form -->
					<validation-observer ref="simpleRules">
						<b-form
							method="POST"
							class="auth-reset-password-form mt-2"
							@submit.prevent="validationForm"
						>
							<!-- password -->
							<b-form-group
								label="New Password"
								label-for="reset-password-new"
							>
								<validation-provider
									#default="{ errors }"
									name="Password"
									vid="Password"
									rules="required|password"
								>
									<b-input-group
										class="input-group-merge"
										:class="
											errors.length > 0
												? 'is-invalid'
												: null
										"
									>
										<b-form-input
											id="reset-password-new"
											v-model="password"
											:type="password1FieldType"
											:state="
												errors.length > 0 ? false : null
											"
											class="form-control-merge"
											name="reset-password-new"
											placeholder="············"
										/>
										<b-input-group-append is-text>
											<feather-icon
												class="cursor-pointer"
												:icon="password1ToggleIcon"
												@click="
													togglePassword1Visibility
												"
											/>
										</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{
										errors[0]
									}}</small>
								</validation-provider>
							</b-form-group>

							<!-- confirm password -->
							<b-form-group
								label-for="reset-password-confirm"
								label="Confirm Password"
							>
								<validation-provider
									#default="{ errors }"
									name="Confirm Password"
									rules="required|confirmed:Password"
								>
									<b-input-group
										class="input-group-merge"
										:class="
											errors.length > 0
												? 'is-invalid'
												: null
										"
									>
										<b-form-input
											id="reset-password-confirm"
											v-model="cPassword"
											:type="password2FieldType"
											class="form-control-merge"
											:state="
												errors.length > 0 ? false : null
											"
											name="reset-password-confirm"
											placeholder="············"
										/>
										<b-input-group-append is-text>
											<feather-icon
												class="cursor-pointer"
												:icon="password2ToggleIcon"
												@click="
													togglePassword2Visibility
												"
											/>
										</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{
										errors[0]
									}}</small>
								</validation-provider>
							</b-form-group>

							<!-- submit button -->
							<b-button block type="submit" variant="primary">
								Set New Password
							</b-button>
						</b-form>
					</validation-observer>
				</template>

				<p class="text-center mt-2">
					<b-link :to="{ name: 'auth-login-v1' }">
						<feather-icon icon="ChevronLeftIcon" /> Back to login
					</b-link>
				</p>
			</b-card>
			<!-- /Reset Password v1 -->
		</div>
	</div>
</template>

<script>
	import { ValidationProvider, ValidationObserver } from "vee-validate";
	import VuexyLogo from "@core/layouts/components/Logo.vue";
	import {
		BCard,
		BCardTitle,
		BCardText,
		BForm,
		BFormGroup,
		BInputGroup,
		BInputGroupAppend,
		BLink,
		BFormInput,
		BButton,
		BSpinner,
	} from "bootstrap-vue";
	import { required } from "@validations";
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import apis from "@configs/apis";
	import { ref, onMounted, computed } from "@vue/composition-api";
	import store from "@/store";

	export default {
		components: {
			VuexyLogo,
			BCard,
			BButton,
			BCardTitle,
			BCardText,
			BForm,
			BFormGroup,
			BInputGroup,
			BLink,
			BFormInput,
			BInputGroupAppend,
			ValidationProvider,
			ValidationObserver,
			BSpinner,
		},
		setup(props, ctx) {
			const code = ctx.root.$router.currentRoute.params.code;
			const simpleRules = ref(null);
			const isLoadingCode = ref(true);
			const showUpdateSuccess = ref(false);
			const cPassword = ref("");
			const password = ref("");
			const password1FieldType = ref("password");
			const password2FieldType = ref("password");

			onMounted(async () => {
				try {
					const response = await fetcher(
						apis.verifyResetPasswordCode + "/" + code,
						FetchMethod.GET
					);
					isLoadingCode.value = false;
				} catch (e) {
					if (e.code == -4) {
						ctx.root.$router.push({ name: "other-link-expired" });
					}
				}
			});

			const password1ToggleIcon = computed(() =>
				password1FieldType.value === "password" ? "EyeIcon" : "EyeOffIcon"
			);

			const password2ToggleIcon = computed(() =>
				password2FieldType.value === "password" ? "EyeIcon" : "EyeOffIcon"
			);

			function togglePassword1Visibility() {
				password1FieldType.value =
					password1FieldType.value === "password" ? "text" : "password";
			}

			function togglePassword2Visibility() {
				password2FieldType.value =
					password2FieldType.value === "password" ? "text" : "password";
			}

			function validationForm() {
				simpleRules.value.validate().then(async (success) => {
					if (success) {
						isLoadingCode.value = true;

						try {
							const response = await fetcher(
								apis.resetPasswordConfirm,
								FetchMethod.POST,
								{
									code,
									newPassword: password.value,
								}
							);

							showUpdateSuccess.value = true;
						} catch (e) {
							if (e.code == -4) {
								ctx.root.$router.push({
									name: "other-link-expired",
								});
							} else {
								store.commit("app/SHOW_ERROR_DIALOG", {
									isOpen: true,
									message: e,
								});
							}
						} finally {
							isLoadingCode.value = false;
						}
					}
				});
			}

			return {
				isLoadingCode,
				cPassword,
				password,

				required,
				password1FieldType,
				password2FieldType,
				password1ToggleIcon,
				password2ToggleIcon,

				togglePassword1Visibility,
				togglePassword2Visibility,
				validationForm,

				simpleRules,
				showUpdateSuccess,
			};
		},
	};
</script>

<style lang="scss">
	@import "@core/scss/vue/pages/page-auth.scss";

	#loading-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 50px;
	}
</style>
